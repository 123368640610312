import React, { useState } from 'react'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import Title from 'components/Shared/Title'
import { Button } from 'components/Shared/_Button'
import { ButtonExtLink } from 'components/Shared/_Links'

import memberGallery from 'constant/member-gallery.constant'

//#region STYLESE
import styled from 'styled-components'
const YearWrapper = styled.div`
  ${p => p.theme.fullbleed}
  text-align: left;
  width: 100%;
  height: 5rem;
  background-color: rgba(var(--color-primary-rgb), 0.2);
  padding-left: var(--padding-side);

  button {
    --border-radius: 0;
    --padding: 0 2rem;
    --background-color: transparent;
    --color: var(--color-primary);
    --hover-background-color: var(--color-secondary);
    height: 100%;
    line-height: 1;
  }
  .active {
    height: 100%;
    --background-color: var(--color-primary);
    --color: var(--color-white);
  }
`
const AlbumWrapper = styled.section`
  text-align: left;
  min-height: calc(100vh - var(--header-height) - 100px);

  --column-count: 2;
  ${p => p.theme.mq.md`
    --column-count: 3;
  `}
  column-gap: 5rem;
  column-count: var(--column-count);
  margin-bottom: calc(var(--margin-default) * 2.5);
`
const Album = styled.div`
  --text-align: left;
  margin-bottom: var(--margin-default);
  break-inside: avoid;
  -webkit-column-break-inside: avoid;

  h4 {
    border-bottom: 0.2rem solid rgba(0, 0, 0, 0.1);
  }
  .time {
    margin-bottom: 1rem;
    color: var(--color-primary);
    --font-size: calc(var(--font-size-default) * 0.8);
  }
  .link {
    display: block;
    --color: var(--color-gray);
    --border-radius: 0;
    --background-color: transparent;
  }
`
//#endregion

const Gallery = ({ data }) => {
  const { seo } = data
  const { title, description } = seo.frontmatter.gallery
  const [index, setIndex] = useState(0)

  const onClick = idx => {
    setIndex(idx)
  }
  return (
    <PageContent>
      <Seo title={title} description={description} />
      <YearWrapper>
        {memberGallery.map((item, i) => (
          <Button
            key={`year-${i}`}
            className={i === index ? 'active' : undefined}
            onClick={() => onClick(i)}
          >
            {item.year}
          </Button>
        ))}
      </YearWrapper>

      <Title>活動相簿連結</Title>

      <AlbumWrapper>
        {memberGallery[index].events.map((event, i) => {
          return (
            <Album key={`ablum-${i}`}>
              <h4>{event.title}</h4>
              <p className='time'>{event.time}</p>
              {event.albums.map((album, i) => {
                return (
                  <ButtonExtLink
                    href={album.link}
                    target='_blank'
                    rel='noreferrer'
                    className='link'
                    key={`link-${i}`}
                  >
                    {album.title}
                  </ButtonExtLink>
                )
              })}
            </Album>
          )
        })}
      </AlbumWrapper>
    </PageContent>
  )
}

export default Gallery

export const query = graphql`
  {
    seo: mdx(frontmatter: { type: { eq: "seo_member" } }) {
      frontmatter {
        gallery {
          title
          description
        }
      }
    }
  }
`
