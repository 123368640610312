/***************************************\

Member Gallery data format:
{
  year: "yyyy",
  events: [
    {
      title: "event title",
      time: "yyyymmdd",
      albums: [
        {
          title: "albums title",
          link: "link to photo albums"
        },
      ]
    },
  ]
},

**************************************/

const memberGallery = [
  {
    year: "2022",
    events: [
      {
        title: "晶晶第十九次公演『好久不見』",
        time: "20220731",
        albums: [
          {
            title: "綠竹班",
            link: "https://photos.app.goo.gl/CyFcL76TYs25rkwPA"
          },
          {
            title: "藍竹班",
            link: "https://photos.app.goo.gl/G2YiXDSZbZ5AzVg3A"
          },
          {
            title: "紫竹班",
            link: "https://photos.app.goo.gl/scwmXXUxaEyXgRHr5"
          },
          {
            title: "Concert班",
            link: "https://photos.app.goo.gl/XxgDQX74UJmDdHHa9"
          },
          {
            title: "Youth班",
            link: "https://photos.app.goo.gl/ociEYRS51GBbHS9k6"
          },
          {
            title: "畢業生",
            link: "https://photos.app.goo.gl/9qVsLLcpC3X4WZj87"
          },
          {
            title: "幕後",
            link: "https://photos.app.goo.gl/m6VGsGpgC7mC1XXLA"
          },
        ]
      },
      {
        title: "2021團慶「最愛班鳩與高歌」",
        time: "202201",
        albums: [
          {
            title: "晶歌Bells Through The Ages ~ Concert班 at SOGO",
            link: "https://photos.app.goo.gl/JoCgTFB2dKj7x6oR7"
          }
        ]
      },
    ]
  },
  {
    year: "2021",
    events: [
      {
        title: "2021團慶「最愛班鳩與高歌」",
        time: "202112",
        albums: [
          {
            title: "Winter Fantasy with B&G",
            link: "https://photos.app.goo.gl/wVXSgt5PE9Uq3ej1A"
          },
          {
            title: "Winter fantasy with 攻城獅 ~ B&G",
            link: "https://photos.app.goo.gl/95nidE7GS2a4WREo9"
          },
          {
            title: "大魯閣聖誕築夢點燈活動",
            link: "https://photos.app.goo.gl/XYXiJ1sgo9eu2dSU9"
          },
          {
            title: "晶歌Bells Through The Ages ~ Concert 班",
            link: "https://photos.app.goo.gl/rs7P8fMdTwNfqn6M9"
          },
          {
            title: "Songs in the Night ~ Youth 班 - 1",
            link: "https://photos.app.goo.gl/Y2wYxpbu3m4oeL7h7"
          },
          {
            title: "Songs in the Night ~ Youth 班 - 2",
            link: "https://photos.app.goo.gl/yN3odj3om2LrMraW8"
          },
        ]
      },
    ]
  },
  {
    year: "2020",
    events: [
      {
        title: "2020 晶晶畢業生音樂會_起舞飛揚",
        time: "20201219",
        albums: [
          {
            title: "22020 晶晶畢業生音樂會_起舞飛揚",
            link: "https://photos.app.goo.gl/KRHxKzitKmKFkbq99"
          }
        ]
      },
      { 
        title: "晶彩二十",
        time: "20201219",
        albums: [
          {
            title: "彩虹班",
            link: "https://photos.app.goo.gl/fUX71GX2tCmg9Rs59"
          },
          {
            title: "綠竹班",
            link: "https://photos.app.goo.gl/ivDgsbB2veKRbrmHA"
          },
          {
            title: "藍竹班",
            link: "https://photos.app.goo.gl/MrAMam1ntyJb1mHb9"
          },
          {
            title: "紫竹班",
            link: "https://photos.app.goo.gl/JRCLs9nyiFmwfxqYA"
          },
          {
            title: "Concert班",
            link: "https://photos.app.goo.gl/NkMG8mnoFkmBYvUp7"
          },
          {
            title: "Youth班",
            link: "https://photos.app.goo.gl/6W7ur7VZbgSNiBgf8"
          },
          {
            title: "親子合唱",
            link: "https://photos.app.goo.gl/DXRnNLgVKqKKEq4w9"
          },
          {
            title: "團友團",
            link: "https://photos.app.goo.gl/TCki9mw889Ci8Mu46"
          },
          {
            title: "大合唱",
            link: "https://photos.app.goo.gl/NGsHp837NFwM8gDR8"
          },
          {
            title: "2020 晶晶畢業生音樂會",
            link: "https://photos.app.goo.gl/KRHxKzitKmKFkbq99"
          }
        ]
      },
      {
        title: "風之頌聯合音樂會",
        time: "20201120",
        albums: [
          {
            title: "風之頌聯合音樂會",
            link: "https://photos.app.goo.gl/2C6SQT8crkdvv5eX9"
          }
        ]
      },
      {
        title: "202008 阿卡貝拉夏令營",
        time: "20200821-23",
        albums: [
          {
            title: "20200821_古錐活力組",
            link: "https://photos.app.goo.gl/K8oGqv6tZbbNzarQ9"
          },
          {
            title: "20200822_A咖組",
            link: "https://photos.app.goo.gl/FiUviomMgPZERuDGA"
          },
          {
            title: "20200823_A咖組",
            link: "https://photos.app.goo.gl/9SW9pc8FsXypM4Gi7"
          },
          {
            title: "20200823_結業音樂會",
            link: "https://photos.app.goo.gl/jQwh8tMf2Q1nBgaM7"
          },
        ]
      },
    ]
  },
  {
    year: "2019",
    events: [
      {
        title: "團慶音樂會",
        time: "20191229",
        albums: [
          {
            title: "Sogo場＿錄影",
            link: "https://photos.app.goo.gl/1WDZteu7uDE66jZX6"
          },
          {
            title: "P/C讓我們一起唱美麗的歌謠＿彩排",
            link: "https://photos.app.goo.gl/ShLevNAsRdTRu1zC8"
          },
          {
            title: "竹中場＿錄影",
            link: "https://photos.app.goo.gl/wGzBMTRP37t6Z5zU6"
          },
          {
            title: "晶聲傳唱音樂會",
            link: "https://photos.app.goo.gl/zLGTD5dKzn7ULSc99"
          },
          {
            title: "小小班",
            link: "https://photos.app.goo.gl/EfVbJ5j9kkeZBoq2A"
          },
          {
            title: "彩虹班",
            link: "https://photos.app.goo.gl/knEWN4d33DCipLFx5"
          },
          {
            title: "綠竹",
            link: "https://photos.app.goo.gl/aMX8DetBMFEJJwJJA"
          },
          {
            title: "藍竹",
            link: "https://photos.app.goo.gl/4L5AA9QgVYQEWJDY9"
          },
          {
            title: "紫竹",
            link: "https://photos.app.goo.gl/fDYgEFR2EQauTpbY6"
          },
          {
            title: "Concert",
            link: "https://photos.app.goo.gl/1iyEzbyHCx3SpCPo8"
          },
          {
            title: "Youth",
            link: "https://photos.app.goo.gl/SHFebNjUHBaDy3tf9"
          },
          {
            title: "親師團",
            link: "https://photos.app.goo.gl/f9QLA3f2rdZJYvar6"
          },
        ]
      },
      {
        title: "新竹市合唱觀摩會",
        time: "20191222",
        albums: [
          {
            title: "新竹市合唱觀摩會",
            link: "https://photos.app.goo.gl/n1dZjunPuJNJwVaP6"
          },
        ]
      },
      {
        title: "山城樂韻音樂會",
        time: "20191006",
        albums: [
          {
            title: "山城樂韻音樂會",
            link: "https://photos.app.goo.gl/o8CLjF2LjGwSSc6H7"
          },
        ]
      },
      {
        title: "2019夏令營『英雄聯萌』",
        time: "20190816-17",
        albums: [
          {
            title: "Day 1",
            link: "https://photos.app.goo.gl/d3WwtF7FmSWNmdKLA"
          },
          {
            title: "Day 2",
            link: "https://photos.app.goo.gl/pTAZVHCuvB9bvCNf9"
          },
        ]
      },
      {
        title: "2019雙城音樂會-苗北場",
        time: "20190821",
        albums: [
          {
            title: "2019雙城音樂會-苗北場",
            link: "https://photos.app.goo.gl/QEwR2DRNPN9PK1UU9"
          },
        ]
      },
      {
        title: "台北國際合唱節",
        time: "20190804",
        albums: [
          {
            title: "台北國際合唱節",
            link: "https://photos.app.goo.gl/LuGP4j1QzyazZaW46"
          },
          {
            title: "排練相簿",
            link: "https://photos.app.goo.gl/tgGcev5uXoogGC6c9"
          }
        ]
      },
      {
        title: "晶晶第十八次公演『爵妙愛麗絲』",
        time: "20190608",
        albums: [
          {
            title: "彩虹班",
            link: "https://photos.app.goo.gl/kbNdAr1un93Li1XE8"
          },
          {
            title: "綠竹班",
            link: "https://photos.app.goo.gl/44kCYmN62BMaqJqN7"
          },
          {
            title: "藍竹班",
            link: "https://photos.app.goo.gl/eTa6RDvb118ECjYp8"
          },
          {
            title: "音樂劇演出",
            link: "https://photos.app.goo.gl/rEyowGWgBh86QxHAA"
          },
          {
            title: "紫竹Ａ班",
            link: "https://photos.app.goo.gl/CqLuinwXYbNM68LG9"
          },
          {
            title: "紫竹Ｂ班",
            link: "https://photos.app.goo.gl/GUT7smUbwKMn3nHx8"
          },
          {
            title: "紫竹ＡＢ班",
            link: "https://photos.app.goo.gl/sUhnGSTPRBBNoG8K7"
          },
          {
            title: "Concert班",
            link: "https://photos.app.goo.gl/i7PRQwt6BJ1WC73FA"
          },
          {
            title: "Youth班",
            link: "https://photos.app.goo.gl/osPoKnWALqZ6a76N6"
          },
          {
            title: "畢業生",
            link: "https://photos.app.goo.gl/eWWJmEMN8Hyzwt9d9"
          },
          {
            title: "晚上場大合唱",
            link: "https://photos.app.goo.gl/RKkTeGn11q58yizi7"
          },
          {
            title: "幕後花絮",
            link: "https://photos.app.goo.gl/URLmM2qGitWCjaMy6"
          },
        ]
      },
      {
        title: "晶晶冬令音樂營",
        time: "201901",
        albums: [
          {
            title: "第一天",
            link: "https://photos.app.goo.gl/JcNAThgNGPfty9Fo8"
          },
          {
            title: "第二天",
            link: "https://photos.app.goo.gl/PDbDEqewAbmPf1VXA"
          },
          {
            title: "第三天",
            link: "https://photos.app.goo.gl/dxWpr9GEQF7py84AA"
          },
          {
            title: "結業音樂會",
            link: "https://photos.app.goo.gl/AZgnnvLaSGWDchgF8"
          },
        ]
      },
      {
        title: "森林裡的龍貓交響音樂會",
        time: "20190106",
        albums: [
          {
            title: "森林裡的龍貓交響音樂會",
            link: "https://photos.app.goo.gl/Pps2dcTzFXbSB3hF6"
          },
        ]
      }
    ]
  },
  {
    year: "2018",
    events: [
      {
        title: "新韻之聲音樂會",
        time: "201801118",
        albums: [
          {
            title: "新韻之聲音樂會",
            link: "https://photos.app.goo.gl/Mn3buvaarrdknLc98"
          },
        ]
      },
      {
        title: "晶晶夏令音樂營『愛麗絲夢遊仙境』",
        time: "20180824",
        albums: [
          {
            title: "第一天",
            link: "https://photos.app.goo.gl/uNCSGAgbeDSAmhNCA"
          },
          {
            title: "第二天",
            link: "https://photos.app.goo.gl/jT5KUY9KRA8FTGEn8"
          },
        ]
      },
      {
        title: "阿卡貝拉音樂節 Vocal Asia for Kids",
        time: "201808",
        albums: [
          {
            title: "精彩照片",
            link: "https://photos.app.goo.gl/mdfYodP3gxhonjBi7"
          },
        ]
      },
      {
        title: "維也納合唱音樂節",
        time: "201807",
        albums: [
          {
            title: "精彩照片",
            link: "https://www.facebook.com/media/set/?set=a.2272353289447884&type=1&l=42abb2a5b2"
          },
        ]
      },
      {
        title: "晶晶第十七次公演『囡仔歌晶好聽』",
        time: "20180602",
        albums: [
          {
            title: "彩虹班",
            link: "https://photos.app.goo.gl/iFCFvCW7TH5oAeX78",
          },
          {
            title: "綠竹班",
            link: "https://photos.app.goo.gl/L5E1BVQKsjbReBsp9",
          },
          {
            title: "藍竹班",
            link: "https://photos.app.goo.gl/2P8Xki1aQa4kZbpS9",
          },
          {
            title: "紫竹A班",
            link: "https://photos.app.goo.gl/XcrTX8Vn7Zh39XL19",
          },
          {
            title: "紫竹B班",
            link: "https://photos.app.goo.gl/ybPssGCsxo1TA2Vn8",
          },
          {
            title: "下午場大合唱",
            link: "https://photos.app.goo.gl/Vgk1vkhwxvnRZosN8",
          },
          {
            title: "Concert班",
            link: "https://photos.app.goo.gl/QyNoMhpWJ2FUgQEo7",
          },
          {
            title: "Youth班",
            link: "https://photos.app.goo.gl/qBkSKzihPPUVxhJeA",
          },
          {
            title: "畢業生",
            link: "https://photos.app.goo.gl/ah7W2jiDCmKVuDDQ8",
          },
          {
            title: "晚上場大合唱",
            link: "https://photos.app.goo.gl/u6K5HXQviZa85RzA6",
          },
          {
            title: "公演主持群",
            link: "https://photos.app.goo.gl/A5fs5cDcBY9X4TEX6",
          },
          {
            title: "幕後側拍",
            link: "https://photos.app.goo.gl/KyRAZi8v8aCzRSeBA",
          },
        ]
      },
      {
        title: "超級晶嗓大賽",
        time: "20180324",
        albums: [
          {
            title: "精彩照片集",
            link: "https://photos.app.goo.gl/Z7QX82XxyHYA8XiE3",
          },
        ]
      },
      {
        title: "晶晶冬令音樂營 『晶音聯盟』相簿",
        time: "201801",
        albums: [
          {
            title: "第一天",
            link: "https://photos.app.goo.gl/KU5rwFKekRU3ZvSm1",
          },
          {
            title: "第二天",
            link: "https://photos.app.goo.gl/gzNjt721K1q9O0Gi2",
          },
          {
            title: "第三天",
            link: "https://photos.app.goo.gl/9ypQjbdVbkHThW682",
          },
          {
            title: "結業音樂會錄影",
            link: "https://photos.app.goo.gl/AudD49xZu77d4tEG3",
          },
          {
            title: "結業音樂會照片",
            link: "https://photos.app.goo.gl/g9vi26QTOjgyePzA2",
          },
        ]
      },
    ]
  },
  {
    year: "2017",
    events: [
      {
        title: "Youth班 - 日本合唱之旅 輕井澤國際合唱節",
        time: "201708",
        albums: [
          {
            title: "第一天",
            link: "https://goo.gl/photos/5NiYmcmFThFCALf19",
          },
          {
            title: "第二天",
            link: "https://goo.gl/photos/BccHfPCx13u5fKoP8",
          },
          {
            title: "第三天",
            link: "https://goo.gl/photos/ZcPqC6vGmLHtcvRw5",
          },
          {
            title: "第四天",
            link: "https://goo.gl/photos/DbzSB75U6f5ATUtC7",
          },
        ]
      },
      {
        title: "晶晶夏令音樂營 『美女與野獸』相簿",
        time: "201708",
        albums: [
          {
            title: "彩虹班",
            link: "https://goo.gl/photos/5CXPdrS6WV7BTe5f9",
          },
          {
            title: "綠竹班",
            link: "https://goo.gl/photos/fNUCQesenWc5xokx8",
          },
          {
            title: "藍竹班",
            link: "https://goo.gl/photos/QEHdYdgfur9U359X8",
          },
          {
            title: "團體活動",
            link: "https://goo.gl/photos/ccjSgGLzh414GNJJ6",
          },
          {
            title: "師長剪影",
            link: "https://goo.gl/photos/6HheAArVVu2zh8y58",
          },
          {
            title: "營內晶嗓大賽",
            link: "https://goo.gl/photos/WA1X6M1qtctesmqP6",
          },
          {
            title: "結業音樂會",
            link: "https://goo.gl/photos/qUj1WR7xoUSWe6Eb6",
          },
        ]
      },
      {
        title: "新竹護城河親子公園『夏夜童樂會』",
        time: "201708",
        albums: [
          {
            title: "藍竹班",
            link: "https://goo.gl/photos/HVJhLMqS6PrVZVGk6",
          },
        ]
      },
      {
        title: "晶晶第十六次公演『阿卡貝拉歡樂頌』",
        time: "20170610",
        albums: [
          {
            title: "彩虹班",
            link: "https://goo.gl/photos/3neMfjpZMw4BjjL8A",
          },
          {
            title: "綠竹班",
            link: "https://goo.gl/photos/JmENhaa5YJwBqpjv7",
          },
          {
            title: "藍竹班",
            link: "https://goo.gl/photos/noyPoP6N5R45ZVih9",
          },
          {
            title: "紫竹Ａ班",
            link: "https://goo.gl/photos/qNqMpdmPYnQNLuRL7",
          },
          {
            title: "紫竹Ｂ班",
            link: "https://goo.gl/photos/vXfSSeSH26VRz44x7",
          },
          {
            title: "下午場大合唱",
            link: "https://goo.gl/photos/LQyD5aSodto7ZMF76",
          },
          {
            title: "Concert班",
            link: "https://goo.gl/photos/WUsXfhSrL7txNnA7A",
          },
          {
            title: "Youth班",
            link: "https://goo.gl/photos/fJrxNmJEbzNMuUsW9",
          },
          {
            title: "畢業生",
            link: "https://goo.gl/photos/T2d9up84dxaHMnXj6",
          },
          {
            title: "晚上場大合唱",
            link: "https://goo.gl/photos/6t8rv2ukrqeBaJ2D7",
          },
        ]
      },
      {
        title: "晶晶冬令音樂營 『與阿卡有約』",
        time: "201701",
        albums: [
          {
            title: "晨間活動＆影片",
            link: "https://goo.gl/photos/6vt2Qmyw7xXD6TxW8",
          },
          {
            title: "音樂會表演",
            link: "https://goo.gl/photos/x7tLK26osQe1vdF5A",
          },
          {
            title: "結業式＆影片",
            link: "https://goo.gl/photos/9uW2JPeicZ1ptmms5",
          },
          {
            title: "師長鳳凰會精彩剪影",
            link: "https://goo.gl/photos/V1Mp7LdS2GuFBR8XA",
          },
          {
            title: "紫竹AB班 第一天",
            link: "https://goo.gl/photos/V1Mp7LdS2GuFBR8XA",
          },
          {
            title: "紫竹AB班 第二天",
            link: "https://goo.gl/photos/Zh1YhpQeA4nTdxv66",
          },
          {
            title: "紫竹AB班 第三天",
            link: "https://goo.gl/photos/YEgq9K3vyKuRq69m6",
          },
          {
            title: "Concert班 第一天",
            link: "https://goo.gl/photos/LpBpeJYKDdgqhM5G7",
          },
          {
            title: "Concert班 第二天",
            link: "https://goo.gl/photos/N3MDs4rVffoMGJHB7",
          },
          {
            title: "Concert班 第三天",
            link: "https://goo.gl/photos/oiQu4BoAmXqxP4sk9",
          },
          {
            title: "Youth班 第一天",
            link: "https://goo.gl/photos/oiQu4BoAmXqxP4sk9",
          },
          {
            title: "Youth班 第二天",
            link: "https://goo.gl/photos/qrQR3ZnLxgsrxEnM8",
          },
          {
            title: "Youth班 第三天",
            link: "https://goo.gl/photos/SUFvWBXQTRgFHZKf9",
          },
        ]
      },
    ]
  },
  {
    year: "2016",
    events: [
      {
        title: "晶晶十六週年團慶懇親音樂會各班相簿(表演錄影＆照片)",
        time: "20161218",
        albums: [
          {
            title: "小小班",
            link: "https://goo.gl/photos/TgJ3BijdnKmo6sEYA",
          },
          {
            title: "彩虹班",
            link: "https://goo.gl/photos/KSYAwxCGGD43HZnQ9",
          },
          {
            title: "綠竹班",
            link: "https://goo.gl/photos/LpMQSDmNgm6L4sHm9",
          },
          {
            title: "藍竹班",
            link: "https://goo.gl/photos/NArTUyc8HZxcBr8U7",
          },
          {
            title: "紫竹Ａ班",
            link: "https://goo.gl/photos/L7zR964VL7dC9s7h6",
          },
          {
            title: "紫竹Ｂ班",
            link: "https://goo.gl/photos/eTw5J9aR36G6LEoq8",
          },
          {
            title: "Concert班",
            link: "https://goo.gl/photos/4H1pZp4As6C1uwHe9",
          },
          {
            title: "Youth班",
            link: "https://goo.gl/photos/brVnaVmVP9fk7UoU6",
          },
        ]
      },
      {
        title: "晶晶夏令音樂營 『海洋嘉年華』",
        time: "201608",
        albums: [
          {
            title: "第一天",
            link: "https://goo.gl/photos/fFJvzd9tFCXrw8tM7",
          },
          {
            title: "第二天",
            link: "https://goo.gl/photos/gH4jTksGmQzuQQtE7",
          },
          {
            title: "結業式/音樂會影片",
            link: "https://goo.gl/photos/rxqf8FaAsSAGkdL46",
          },
        ]
      },
      {
        title: "台東行旅～台東晶晶聯合合唱夏令營相簿",
        time: "201607",
        albums: [
          {
            title: "第一天",
            link: "https://goo.gl/photos/ugEuWUkCWgTgPJQC8",
          },
          {
            title: "第二天",
            link: "https://goo.gl/photos/fr9Coa47FnZptNvt9",
          },
          {
            title: "第三天",
            link: "https://goo.gl/photos/oxiFBXTe3Gx66JFq5",
          },
          {
            title: "第四天",
            link: "https://goo.gl/photos/oxiFBXTe3Gx66JFq5",
          },
        ]
      },
      {
        title: "晶晶加拿大音樂旅行相簿",
        time: "201607",
        albums: [
          {
            title: "第二天",
            link: "https://goo.gl/photos/3n99xz8UmgPzTcQ17",
          },
          {
            title: "第三天",
            link: "https://goo.gl/photos/JXCkPu9s1SRrB7tL6",
          },
          {
            title: "第四天",
            link: "https://goo.gl/photos/jLEJ6FWmcbywygxE9",
          },
          {
            title: "第五天",
            link: "https://goo.gl/photos/jLEJ6FWmcbywygxE9",
          },
          {
            title: "第六天",
            link: "https://goo.gl/photos/KCZJ9ftV7iwL9p3h9",
          },
          {
            title: "第七天",
            link: "https://goo.gl/photos/2AUsnpE1n8ADQo1L6",
          },
          {
            title: "第八天",
            link: "https://goo.gl/photos/ptx59VGB2tzdhiks7",
          },
          {
            title: "第九天",
            link: "https://goo.gl/photos/Pa14vc6wFMUQ27Pv9",
          },
          {
            title: "第十天",
            link: "https://goo.gl/photos/2udoUWDLMAP9Gbpz5",
          },
          {
            title: "第十一天",
            link: "https://goo.gl/photos/rpJG6RpXrojUSXuh7",
          },
          {
            title: "音樂會影片",
            link: "https://goo.gl/photos/toZ4TAaGNkbyJ6bt6",
          },
        ]
      },
      {
        title: "晶晶第十五次公演",
        time: "20160605",
        albums: [
          {
            title: "彩虹班",
            link: "https://goo.gl/photos/CRCjgsT7YHGi6j2q6",
          },
          {
            title: "綠竹班",
            link: "https://goo.gl/photos/auePsVUworN9sWEX8",
          },
          {
            title: "紫竹二班",
            link: "https://goo.gl/photos/JNCaUmor5auynxPGA",
          },
          {
            title: "藍竹班",
            link: "https://goo.gl/photos/iaBcbyGmrm5Q6gwL6",
          },

          {
            title: "紫竹一班",
            link: "https://goo.gl/photos/NDTAtvFdGY9ZKxy26",
          },
          {
            title: "Concert班",
            link: "https://goo.gl/photos/v9JGVrHs3JcbHTKk6",
          },
          {
            title: "Youth班",
            link: "https://goo.gl/photos/RrJpq42xXbfCgppw6",
          },
          {
            title: "畢業生",
            link: "https://goo.gl/photos/Ns1GpLN2wCBurX6Q6",
          },
          {
            title: "下午場大合唱",
            link: "https://goo.gl/photos/BEVSoEjPih87vbpR6",
          },
          {
            title: "晚上場大合唱",
            link: "https://goo.gl/photos/cbZotvcFVYAEFdY37",
          },
        ]
      },
      {
        title: "Youth班與木樓聯演『謠傳俄羅斯』音樂會相簿（照片及影片）",
        time: "20160529",
        albums: [
          {
            title: "『謠傳俄羅斯』",
            link: "https://goo.gl/photos/4xaVWvxsKBHq8tZK6",
          },
        ]
      },
      {
        title: "晶晶冬令音樂營相簿",
        time: "201602",
        albums: [
          {
            title: "精彩照片",
            link: "https://www.facebook.com/crystalchoirtw/photos/?tab=albums&albums_id=1363019657047923",
          },
          {
            title: "結業影片",
            link: "https://goo.gl/photos/NyZi57k3ibmhsNq68",
          },
        ]
      },
    ]
  },
  {
    year: "2015",
    events: [
      {
        title: "晶晶十五週年團慶音樂會『晶曲獎』",
        time: "20151226",
        albums: [
          {
            title: "小小班 ",
            link: "https://goo.gl/photos/VypeXt6howm3mXW2A",
          },
          {
            title: "彩虹班",
            link: "https://goo.gl/photos/WCoYAWoXyaZanHAi9",
          },
          {
            title: "綠竹班",
            link: "https://goo.gl/photos/9jYwAXCvi6iZhkms7",
          },
          {
            title: "藍竹班",
            link: "https://goo.gl/photos/tniDd253FkdX2CKq5",
          },
          {
            title: "紫竹Ａ班",
            link: "https://goo.gl/photos/9tNb9u7ajwJwXac17",
          },
          {
            title: "紫竹Ｂ班",
            link: "https://goo.gl/photos/r7pqMk9V61b5gCja8",
          },
          {
            title: "Concert班",
            link: "https://goo.gl/photos/NvNr3gCtnuNcx46Q9",
          },
          {
            title: "Youth班",
            link: "https://goo.gl/photos/tFYPXhaTcsrZNzbq6",
          },
          {
            title: "團友團",
            link: "https://goo.gl/photos/wVAAgHFSG5Bf8EKF9",
          },
          {
            title: "演出影片",
            link: "https://goo.gl/photos/hRCp8kLjPaAq5ZzM9",
          },
        ]
      },
      {
        title: "年度公演精彩照片",
        time: "20150606",
        albums: [
          {
            title: "『晶典』",
            link: "https://drive.google.com/folderview?id=0B2DGHiQhXpb6fjlaNUd3YVhQcjlJZnF5V0RDWG5MZnphcjdoRmt2Vm91MHN5c28waVVEY00&usp=sharing",
          },
        ]
      },
      {
        title: "新竹市父親節慶祝會藍竹班演出精彩照片",
        time: "20150803",
        albums: [
          {
            title: "父親節慶祝會",
            link: "https://drive.google.com/folderview?id=0B2DGHiQhXpb6bkJ2V3dCNldLd1E&usp=sharing",
          },
        ]
      },
      {
        title: "國際合唱觀摩會精彩照片",
        time: "20150807",
        albums: [
          {
            title: "『謠傳俄羅斯』",
            link: "https://drive.google.com/folderview?id=0B1PIE1M81iKGflhELU5JNG9IMUwzRVBaSkJzYkJidElpM09hc3FPU2ppNzNrVDdnMDIzcUU&usp=sharing",
          },
        ]
      },
      {
        title: "夏令音樂營精彩照片",
        time: "20150821",
        albums: [
          {
            title: "夏令音",
            link: "https://drive.google.com/folderview?id=0B1PIE1M81iKGNmUtLVF1aklmQXc&usp=sharing",
          },
        ]
      },
      {
        title: "年度公演",
        time: "20150606",
        albums: [
          {
            title: "『謠傳俄羅斯』",
            link: "https://www.facebook.com/media/set/?set=a.1110624512287440.1073741861.144618842221350&type=3",
          },
        ]
      },
      {
        title: "紐約行相簿",
        time: "20150209",
        albums: [
          {
            title: "第一天",
            link: "https://photos.app.goo.gl/KAzS13pEc5MgyCV1A",
          },
          {
            title: "第二天",
            link: "https://photos.app.goo.gl/GioPbpq8hmjVcLDo7",
          },
          {
            title: "第三天",
            link: "https://photos.app.goo.gl/fz5djYtXRRXBV7dX8",
          },
          {
            title: "第四天",
            link: "https://photos.app.goo.gl/hXfpxoXQQgZnxzKcA",
          },
          {
            title: "第五天",
            link: "https://photos.app.goo.gl/7dRbv5cPcNcSqRik8",
          },
          {
            title: "第六天",
            link: "https://photos.app.goo.gl/etaYcrxsqeLmrgmF9",
          },
          {
            title: "第七天",
            link: "https://photos.app.goo.gl/5WnnrGYskx9fR8hv8",
          },
          {
            title: "第八天",
            link: "https://photos.app.goo.gl/DKoeVpuP5hdGcEM58",
          }
        ]
      },
      {
        title: "冬令音樂營",
        time: "20150130",
        albums: [
          {
            title: "第一天",
            link: "https://www.facebook.com/media/set/?set=a.1016961468320412.1073741858.144618842221350&type=3",
          },
          {
            title: "第二天",
            link: "https://www.facebook.com/media/set/?set=a.1017435264939699.1073741859.144618842221350&type=3",
          },
          {
            title: "第三天",
            link: "https://www.facebook.com/media/set/?set=a.1018000408216518.1073741860.144618842221350&type=3",
          }
        ]
      },
      {
        title: "晶晶團慶三部曲-[晶生晶世愛傳千里]與美國團聯演音樂會",
        time: "20150102",
        albums: [
          {
            title: "團慶三部曲",
            link: "https://www.facebook.com/media/set/?set=a.999898160026743.1073741857.144618842221350&type=3",
          },
        ]
      },
    ]
  },
  {
    year: "2014",
    events: [
      {
        title: "晶晶團慶二部曲--三芝雙連安養中心關懷獻唱",
        time: "20141220",
        albums: [
          {
            title: "精彩照片集",
            link: "https://www.facebook.com/media/set/?set=a.986978774652015.1073741855.144618842221350&type=3",
          }
        ]
      },
      {
        title: "晶晶團慶首部曲--台北動物園動物狂歡音樂會",
        time: "20141214",
        albums: [
          {
            title: "精彩照片集",
            link: "https://www.facebook.com/media/set/?set=a.983081845041708.1073741854.144618842221350&type=3",
          },
        ]
      },
      {
        title: "晶晶夏令音樂營",
        time: "20140822-23",
        albums: [
          {
            title: "第一天",
            link: "https://www.facebook.com/media/set/?set=a.914184131931480.1073741852.144618842221350&type=3",
          },
          {
            title: "第二天",
            link: "https://www.facebook.com/media/set/?set=a.914735051876388.1073741853.144618842221350&type=3",
          }
        ]
      },
      {
        title: "與日本夙川兒童合唱團聯演",
        time: "20140813",
        albums: [
          {
            title: "精彩照片集",
            link: "https://www.facebook.com/media/set/?set=a.912195918796968.1073741850.144618842221350&type=3",
          }
        ]
      },
      {
        title: "ICW",
        time: "201407",
        albums: [
          {
            title: "精彩照片集",
            link: "https://www.facebook.com/media/set/?set=a.912193252130568.1073741849.144618842221350&type=3",
          }
        ]
      },
      {
        title: "年度公演",
        time: "20140607",
        albums: [
          {
            title: "精彩照片集",
            link: "https://plus.google.com/u/0/photos/105046422659161165696/albumss/6023634270187800017",
          }
        ]
      },
      {
        title: "冬令音樂營",
        time: "20140124-0126",
        albums: [
          {
            title: "精彩照片集",
            link: "https://www.facebook.com/media/set/?set=a.793351564014738.1073741846.144618842221350&type=3",
          }
        ]
      },
    ]
  },
];

export default memberGallery;

export const recentAlbum = memberGallery[0].events.slice(0,6)